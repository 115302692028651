<template>
    <component :is="alipayAuthorizeDemand"></component>
</template>
<script>
import { mapGetters } from "vuex";
import alipayAuthorize2 from "@/template/AliPay/alipayAuthorize2.vue";
export default {
    components: {
        alipayAuthorize2,
    },
    computed: {
        ...mapGetters(["mealRules"]),
        alipayAuthorizeDemand: {
            get: function () {
          
                return "alipayAuthorize2";
            },
        },
    },
};
</script>
