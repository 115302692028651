<template>
    <div style="background: #ffffff">
        <div class="noalipay_content">
            <Header backType="white" :back="!noBack" :backEvent="true" @backEvent="backPath"></Header>
            <div class="txt">
                <div class="imgDiv">
                    <img class="img" src="../../assets/img/alipay.png" alt="" />
                    <p>支付宝授权</p>
                </div>

                <template v-if="mealRules.tenant_id == '06e40b0d-1dff-11e8-b50f-0242ac120607'">
                    <p class="alipay_tip">请使用支付宝开通授权：员工确认<span class="fontWeight">因公消费</span>，由&lt;<span class="fontWeight">智会云</span>&gt;<span class="fontWeight">实时扣款</span>支付，<span class="fontWeight">无需</span>个人垫支。</p>
                </template>
                <template v-else>
                    <p class="alipay_tip">请使用支付宝开通授权：员工<span class="fontWeight">因公消费时</span>由{{ mealRules.tenant_name == "会引擎-协办会-辉瑞" ? "【智慧科技北京】" : "【智会云】" }}<span class="fontWeight">支付</span>，金额从企业账户<span class="fontWeight">实时扣款，无需</span>个人垫支。</p>
                </template>

                <div class="toCard">
                    <div class="toCard-content" :class="stepOne ? ' toCard-content-g' : ''">
                        <div v-if="!stepOne" class="toCard-content-l1"></div>
                        <div v-else class="toCard-content-l2">
                            <div class="y"></div>
                        </div>
                        <div style="display: flex" class="tocardtop">
                            <div style="margin-left: 10px; justify-content: space-between">
                                <p class="fontWeight" style="text-align: left">个人授权</p>
                                <p class="text-left size12 color_90">唤起支付宝，授权个人支付宝信息</p>
                            </div>
                        </div>
                        <div v-if="!stepOne" style="margin-left: 10px" class="go_btn grey_back color_3875" @click="GenerateUserAuthUrl(true)">去授权</div>
                        <div v-else style="margin-left: 10px" class="go_btn colorGre">已授权</div>
                    </div>
                </div>
                <div class="card_tow">
                    <div v-if="stepTwo && stepOne" class="toCard-content-l2">
                        <div class="y"></div>
                    </div>
                    <div v-else class="toCard-content-l1"></div>
                    <div style="display: flex" class="tocardtop">
                        <div style="margin-left: 10px; justify-content: space-between">
                            <p class="fontWeight" style="text-align: left">企业签约授权</p>
                            <p class="text-left size12 color_90">开通授权因公消费，金额由企业账户扣款</p>
                        </div>
                    </div>
                    <div v-if="!stepTwo && !stepOne" style="margin-left: 10px; border: 1px solid #dedede; color: #909399" class="go_btn">尚未授权</div>
                    <div v-if="!stepTwo && stepOne" style="margin-left: 10px" class="go_btn grey_back color_3875" @click="GenerateMemberAuthUrl(true)">去授权</div>
                    <div v-if="stepTwo" style="margin-left: 10px" class="go_btn colorGre">已授权</div>
                </div>
            </div>
            <div :class="alipayFlg ? 'display_block' : 'display_none'" class="all_screen">
                <div style="margin: 70% auto">
                    <div class="type theme_color colorFFF new_radius" @click="payOk()">已完成支付宝授权</div>
                    <div class="type new_radius" style="background: #fff" @click="payOk()">关闭</div>
                </div>
            </div>
        </div>
        <div class="alipay-buttom-btn">
            <div v-if="!noBack" class="type" @click="backPath()">关闭</div>
            <div :class="noBack ? 'wid70' : ''" class="type colorFFF theme_color" @click="getType()">刷新</div>
        </div>
        <BrowserLoader ref="browserLoader" @close="know" />
   
    </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { miceService } from "@/service/miceService.js";
import { SmartStorage } from "smart-core-util";
export default {
    name: "alipayAuthorize2",
    data() {
        return {
            stepOne: false,
            stepTwo: false,
            alipayFlg: false,
            iswx: false,
            shortAlipayProto: "",
            noBack: false,
            openUrl: null,
            openUrl2: null,
            authParams: null,
        };
    },
    computed: {
        ...mapGetters(["orderDetail", "mealRules", "memsg"]),
    },
    async created() {
        await this.$mealInit()
        this.noBack = SmartStorage.get("authorize");
        if(this.$route.query.authorize){
            this.noBack = false
        }
        if(window.__wxjs_environment === "miniprogram"){
            this.noBack = false
        }
        if (SmartStorage.get("authorize") && window.__wxjs_environment !== "miniprogram") {
            this.authParams = {
                sid: this.$route.query.sid||this.mealRules.sid,
                userId: this.$route.query.userId,
                tenaneId: SmartStorage.get("tenant_id"),
                phoneNumber: SmartStorage.get("authMobile") || this.memsg.phoneNumber,
            };
        } else {
            this.authParams = {
                sid: this.mealRules.sid,
                phoneNumber: SmartStorage.get("authMobile") || this.memsg.phoneNumber,
            };
        }
        this.getType();
    },
    methods: {
        backPath() {
            if(this.$route.query.authorize && SmartStorage.get("isFromMore")){
				this.iJsBridge.setResult({});
				this.iJsBridge.close();
                return
            }
            this.$router.go(-1);
        },
        know() {
            this.alipayFlg = true;
        },
        async getType() {
            if (this.alipayFlg) {
                return;
            }
            let res = await this.CheckUserAuth();
            this.stepOne = res.isUserAuth;
            this.stepTwo = res.isMemberAuth;
            if (!this.stepOne) {
                const res1 = await this.GenerateUserAuthUrl();
                if (res1.success && res1.content) {
                    this.openUrl = res1.content;
                    return;
                }
            }
            if (!this.stepTwo) {
                const res2 = await this.GenerateMemberAuthUrl();
                if (res2.success && res2.content) {
                    this.openUrl2 = res2.content;
                    return;
                }
                if (res2.success && res2.content == null) {
                    this.getType();
                    return;
                }
            }
        },
        async CheckUserAuth() {
            let params = {
                userId: this.$route.query.userId || this.memsg.userId,
                phoneNumber: SmartStorage.get("authMobile") || this.memsg.phoneNumber,
            };
            return new Promise((resolve, reject) => {
                miceService
                    .CheckUserAuth(params)
                    .then((res) => {
                        if (res && res.success) {
                            resolve(res.content);
                        } else {
                            resolve({});
                        }
                    })
                    .catch((err) => {
                        reject(false);
                    });
            });
        },
        async GenerateUserAuthUrl(falg) {
            var u = navigator.userAgent;
            if (/MicroMessenger/gi.test(u) && falg) {
                this.$refs.browserLoader.openPop(this.openUrl)
                return;
            }
            if (falg && this.openUrl && !/MicroMessenger/gi.test(u)) {
                window.location.href = this.openUrl;
                this.alipayFlg = true;
                return;
            }
            return new Promise((resolve, reject) => {
                miceService
                    .GenerateUserAuthUrl(this.authParams)
                    .then((res) => {
                        if (res && res.success) {
                            if (res.content) {
                                resolve(res);
                            }
                        } else {
                            resolve({});
                        }
                    })
                    .catch((err) => {
                        reject(false);
                    });
            });
        },
        async GenerateMemberAuthUrl(falg) {
            var u = navigator.userAgent;

            if (/MicroMessenger/gi.test(u) && falg) {
                this.iswx = true;
                this.$refs.browserLoader.openPop(this.openUrl2)
                return;
            }
            if (falg && this.openUrl2) {
                window.location.href = this.openUrl2;
                this.alipayFlg = true;
                return;
            }
            return new Promise((resolve, reject) => {
                miceService
                    .GenerateMemberAuthUrl(this.authParams)
                    .then((res) => {
                        if (res && res.success) {
                            if (res.content) {
                                resolve(res);
                            }
                            
                        } else {
                            resolve({});
                        }
                    })
                    .catch((err) => {
                        reject(false);
                    });
            });
        },
        payOk() {
            this.alipayFlg = false;
            this.getType();
        },
    },
};
</script>
<style scoped lang="scss">
.noalipay_content {
    height: 100%;
    overflow: scroll;
    .all_screen {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        background-color: rgba(121, 121, 121, 0.9);
        z-index: 1;
    }
    .txt {
        width: 90%;
        margin: 0 auto;
        margin-top: 0.44rem;
        padding: 30px 0px;
        font-size: 16px;
        z-index: 10;
        border-radius: 10px;
        .imgDiv {
            width: 100%;
            margin-bottom: 20px;
            padding-bottom: 20px;
            height: auto;
            .img {
                width: 25%;
                margin-left: 15px;
            }
            p {
                margin-left: 20px;
                font-weight: 700;
                font-size: 16px;
            }
        }
        .alipay_tip {
            margin-bottom: 20px;
            line-height: 1.5em;
        }
        .go_btn {
            width: 80px;
            border-radius: 10px;
            height: 30px;
            line-height: 30px;
            text-align: center;
        }
    }
    .tocardtop {
        width: 70%;
    }
    .toCard {
        margin-top: 0.4rem;
        position: relative;
        height: 75px;
        &-content {
            display: flex;
            &-l1 {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 16px;
                height: 16px;
                border: 1px solid #c1c1c1;
                border-radius: 48px;
                color: #ffff;
            }
            &-l2 {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 16px;
                height: 16px;
                background: rgba(0, 142, 36, 0.317);
                border-radius: 48px;
                color: #ffff;
                .y {
                    width: 10px;
                    height: 10px;
                    background: rgba(0, 142, 36, 1);
                    border-radius: 50%;
                    margin: 0 auto;
                }
            }
            &-r {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 85%;
                text-align: left;
            }
            &::before {
                content: "";
                border-left: 2px solid #ccc;
                position: absolute;
                left: 7px;
                height: 58px;
                top: 18px;
            }
            &-g::before {
                content: "";
                border-left: 2px solid rgba(0, 142, 36, 1);
                position: absolute;
                left: 7px;
                height: 58px;
                top: 18px;
            }
        }
    }
    .card_tow {
        display: flex;
    }
    .size12 {
        font-size: 12px;
    }
    .type {
        width: 80%;
        height: 45px;
        margin: 0 auto;
        border-radius: 2em;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        font-size: 14px;
    }
}
.alipay-buttom-btn {
    width: 100%;
    position: absolute;
    bottom: 0.3rem;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    .type {
        width: 40%;
        height: 45px;
        border: 1px solid #e6e7e9;
        color: #90939a;
        margin: 0 auto;
        border-radius: 0.05rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        font-size: 14px;
    }
}
.WXShadow {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 40px;
    z-index: 3;
    img {
        width: 36%;
        height: 25%;
        float: right;
        margin-right: 0.2rem;
        margin-top: 0.2rem;
    }
    .shadowtext {
        font-size: 0.2rem;
        position: fixed;
        top: 2rem;
        width: 80%;
        background: #fff;
        left: 5%;
        text-align: center;
        padding: 0.15rem;
        padding-bottom: 0.25rem;
        border-radius: 0.1rem;
        box-shadow: 0 0 9px 0px #2a292966;
        div {
            font-weight: 600;
            margin-bottom: 0.2rem;
        }
        p {
            font-size: 0.14rem;
            text-align: left;
        }
        input {
            width: 100%!important;
        }
        a {
            display: block;
            width: 60%;
            font-size: 0.16rem;
            background: var(--themeColor);
            color: #fff;
            padding: 0.12rem 0.48rem;
            border-radius: 50px;
            margin: 0 auto;
            margin-top: 0.5rem;
        }
    }
}
.fontWeight {
    font-weight: 700;
}
.display_block {
    display: block;
}
.display_none {
    display: none;
}
.theme_color {
    background: var(--themeColor) !important;
}
.colorFFF {
    color: #fff !important;
}
.color_90 {
    color: #90939a;
}
.grey_back {
    background: var(--opacityColor);
}
.color_3875 {
    color: var(--themeColor);
}
.colorGre {
    color: rgba(0, 142, 36, 1);
    border: 1px solid rgba(0, 142, 36, 1);
}
.wid70 {
    width: 70% !important;
}
.text-left{
    text-align: left;
}
</style>
